
import { defineComponent, onMounted, ref } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useRoute } from "vue-router";
import axios from "axios";
const userInfo = ref(null);

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    // Dropdown1,
  },
  data() {
    return {
      i: 0,
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const route = useRoute();
    const showDiv = ref(false);
    const image = ref();
    if (localStorage.getItem("id_token") != null) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" +
            localStorage.getItem("u_id"),
          {
            headers: {
            token: localStorage.getItem("id_token"),
          },
          }
        )
        .then((response) => {
          userInfo.value = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const showDivForThreeSeconds = () => {
      setTimeout(() => {
        showDiv.value = true;
      }, 2000);
    };
    const getBanner = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getBanner" +
            "?sizeContents=6&page=0&orderBy=1&sort=1&enable=1&displayGroups=1",
          { headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          } }
        )
        .then(({ data }) => {
          image.value = data.data.content;
          // console.log(image.value);
        })
        .catch(({ error }) => {
          console.log(error);
        });
    };
    onMounted(() => {
      showDivForThreeSeconds();
      getBanner();
    });

    return {
      toolbarWidthFluid,
      route,
      MainMenuConfig,
      userInfo,
      showDiv,
      showDivForThreeSeconds,
      getBanner,
      image,
    };
  },
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    //   if (this.$route.path== "/home") {
    //   console.log(this.$route);
    //   setInterval(this.change, 5000);
    // }
    // },
  },
});
