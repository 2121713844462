<template ref="myElement">
  <!--begin::Header-->
  <div
    id="kt_header"
    style="animation-duration: 0.3s;"
    :class="
      'header align-items-stretch ' +
        getClass() +
        ' ' +
        getClassFix() +
        ' ' +
        getClassBg()
    "
    :data-kt-sticky="isHeaderSticky"
    data-kt-sticky-name="header"
    data-kt-sticky-offset="{default: '200px', lg: '300px'}"
  >
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        container: !headerWidthFluid,
      }"
      class="d-flex align-items-center" style="justify-content: space-around;"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_header_menu_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg src="media/icons/duotone/Text/Menu.svg" />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <router-link to="/">
          <img alt="Logo" :src="changLogo()" class="logo-default h-40px h-lg-40px " />
          <img
            alt="Logo"
            src="media/logos/logo.svg"
            class="logo-sticky h-25px h-lg-35px" 
          />
        </router-link>
      </div>
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="align-items-stretch " style="display: flex;justify-content: space-between;"
      >
      <!-- align-items-stretch justify-content-between flex-lg-grow-1 -->
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_menu_nav">
          <KTMenu></KTMenu>
        </div>
        <!--end::Navbar-->

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, nextTick } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { StickyComponent } from "@/assets/ts/components";

import {
  headerWidthFluid,
  headerFixed,
  headerFixedOnMobile,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  data() {
    return {
      positionTop: document.body.scrollTop,
    };
  },
  watch: {
    $route(to, from) {
      // console.log(this.$route.path);
      if (this.$route.path.indexOf("/course/mandatory-list") == -1) {
        this.$nextTick(() => {
          setInterval(() => {
            this.positionTop = document.body.scrollTop;
          }, 500);
        });
      }
    },
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      StickyComponent.reInitialization();
    });

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });
    const changLogo = () => {
      if (route.path.indexOf("course/mandatory-list") > -1) {
        return "media/icon-app/newlogo2.png";
      } else {
        return "media/icon-app/newlogo1.png";
      }
    };
    const getClass = () => {
      if (route.path == "/home") {
        return "header-default";
      } else if (route.path == "/course/voluntary") {
        return "c-header";
      } else if (route.path == "/course/mandatory") {
        return "c-header";
      } else if (route.path == "/contactus") {
        return "c-header";
      } else if (route.path.indexOf("/blogs") > -1) {
        return "c-header";
      } else if (route.path == "/qaboard") {
        return "c-header";
      } else if (route.path.indexOf("/event") > -1) {
        return "c-header";
      } else if (route.path == "/help") {
        return "c-header";
      } else if (route.path.indexOf("course/mandatory-list") > -1) {
        return "header-mandatory";
      }
    };

    return {
      route,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isHeaderSticky,
      getClass,
      changLogo,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setInterval(() => {
        this.positionTop = document.body.scrollTop;
      }, 100);
    });
  },
  methods: {
    getClassFix() {
      if (this.positionTop > 50 && this.$route.path.indexOf("/home") > -1) {
        return "default-fix";
      }else{
        return "";
      }
    },
    getClassBg() {
      if (
        this.positionTop > 80 &&
        this.$route.path.indexOf("/course/mandatory-list") == -1
      ) {
        return "change_color";
      } else {
        return "";
      }
    },
  },
});
</script>

