<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<template>
  <!--begin::Toolbar-->

  <div class="toolbar row" id="kt_toolbar">
    <!--begin::Container-->
    <div class="col-12" v-if="route.path == '/home'">
      <div>
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              v-for="(item, index) in image"
              :key="index"
              :class="index == 0 ? 'active' : ''"
              type="button"
              data-bs-target="#carouselExampleSlidesOnly"
              :data-bs-slide-to="index"
              class="active"
              aria-current="true"
              :aria-label="'Slide' + index + 1"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              v-for="(item, index) in image"
              :key="index"
              :class="index == 0 ? 'carousel-item active' : 'carousel-item'"
            >
              <a :href="item.url" target="_blank">
                <img
                  :src="imageUrl + item.imageBanner"
                  class="d-block w-100"
                  alt="..."
                  style=""
                />
              </a>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleSlidesOnly"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleSlidesOnly"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <!-- <div
          class="container banner-position"
          style="position: absolute;"
          v-if="showDiv"
        >
          <div class="row p-5">
            <div class="col-12">
              <div class="container row">
                <div class="col-12 col-md-12">
                  <b
                    class="text-header"
                    style="font-weight: 700;color: #313131;"
                  >
                    คอร์สออนไลน์ สำหรับพัฒนาบุคลากร <br />ให้มีคุณภาพ
                    ด้วยความรู้ด้านการจัดการ<br />และเทคโนโลยีด้านพลังงาน</b
                  >
                  <p
                    class="text-des mobile-hide"
                    style="font-weight: 400;text-align: left; color: #464646;"
                  >
                    โดย กองพัฒนาทรัพยากรบุคคลากรด้านพลังงาน
                  </p>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex" style="flex-wrap: wrap;">
                    <router-link
                      to="/sign-up"
                      class="btn btn-app m-2 text-btn"
                      v-if="!userInfo"
                      >ลงทะเบียนฟรี</router-link
                    >
                    <router-link
                      to="/sign-up"
                      style="border: 1px solid #DA595A;
                              border-radius: 20px;
                              color: #DA595A;"
                      class="btn m-2 text-btn"
                      v-if="!userInfo"
                      >คู่มือการใช้งาน</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div v-else-if="route.path == '/course/voluntary'" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 350px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                        color: #323563;
                        "
                >
                  หลักสูตรทั่วไป
                </b>

                <p
                  class="text-des"
                  style="font-weight: 400;
                      display: flex;
                      align-items: center;
                      color: #9999D3;"
                >
                  สำหรับบุคคลทั่วไป ที่ต้องการเรียนรู้เทคโนโลยี ด้านพลังงาน
                  และการจัดการด้านพลังงานอย่างมีประสิทธิภาพ
                  โดยมีหลักสูตรให้เลือกมากมาย เมื่อเรียนจบและเข้าทดสอบ
                  จะได้รับหนังสือรับรองออนไลน์จากกองพัฒนาทรัพยากรบุคคลด้านพลังงาน
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap"
      ></div>
    </div>

    <div v-else-if="route.path == '/contactus'" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 250px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                      
                        color: #323563;
                        "
                >
                  ติดต่อเรา
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="route.path == '/help'" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 250px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                       
                        color: #323563;
                        "
                >
                  ช่วยเหลือ
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="route.path.indexOf('/event') > -1" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 250px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                      
                        color: #323563;
                        "
                >
                  กิจกรรม
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap mt-5"
      >
        <div class="page-title d-flex flex-column m-2">
          <!-- <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            {{ title }}
          </h1> -->
          <ul
            v-if="breadcrumbs"
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
          >
            <li class="breadcrumb-item text-dark opacity-75">
              <router-link to="/home" class="text-dark text-category">
                หน้าหลัก
              </router-link>
            </li>
            <li class="breadcrumb-item text-dark opacity-75">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
            <template v-for="(item, index) in breadcrumbs" :key="index">
              <li class="breadcrumb-item">
                <div v-for="(itemlist, i) in MainMenuConfig" :key="i">
                  <div
                    v-for="(itemp, i) in itemlist.pages"
                    :key="i"
                    class="text-dark opacity-75"
                  >
                    <router-link
                      v-if="item == itemp.heading"
                      :to="itemp.route"
                      class="text-link text-category"
                    >
                      {{ itemp.heading }}
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
            </template>
            <li class="breadcrumb-item text-dark opacity-75 text-category">
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else-if="route.path.indexOf('/blogs') > -1" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 250px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                        color: #323563; "
                >
                  ข่าวสารและบทความ
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap mt-5"
      >
        <div class="page-title d-flex flex-column me-3">
          <div class="container">
            <ul
              v-if="breadcrumbs"
              class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            >
              <li class="breadcrumb-item text-dark opacity-75">
                <router-link to="/home" class="text-dark text-category">
                  หน้าหลัก
                </router-link>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <template v-for="(item, index) in breadcrumbs" :key="index">
                <li class="breadcrumb-item">
                  <div v-for="(itemlist, i) in MainMenuConfig" :key="i">
                    <div
                      v-for="(itemp, i) in itemlist.pages"
                      :key="i"
                      class="text-dark opacity-75"
                    >
                      <router-link
                        v-if="item == itemp.heading"
                        :to="itemp.route"
                        class="text-link text-category"
                      >
                        {{ itemp.heading }}
                      </router-link>
                    </div>
                  </div>
                </li>
                <li class="breadcrumb-item text-dark opacity-75">
                  <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
              </template>
              <li class="breadcrumb-item text-dark opacity-75 text-category">
                {{ title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="route.path.indexOf('/qaboard') > -1" class="col-12">
      <div
        id="background"
        style="background: #E9E9EF;
              height: 250px;display: flex;
              justify-content: center;
              align-items: center;"
      >
        <div class="container my-auto">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <div>
                <b
                  class="text-header"
                  style="font-weight: 700;
                        color: #323563;
                        "
                >
                  กระดานถามตอบ
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap mt-5"
      >
        <div class="page-title d-flex flex-column m-2">
          <!-- <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            {{ title }}
          </h1> -->
          <ul
            v-if="breadcrumbs"
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
          >
            <li class="breadcrumb-item text-dark opacity-75">
              <router-link to="/home" class="text-dark text-category">
                หน้าหลัก
              </router-link>
            </li>
            <li class="breadcrumb-item text-dark opacity-75">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
            <template v-for="(item, index) in breadcrumbs" :key="index">
              <li class="breadcrumb-item">
                <div v-for="(itemlist, i) in MainMenuConfig" :key="i">
                  <div
                    v-for="(itemp, i) in itemlist.pages"
                    :key="i"
                    class="text-dark opacity-75"
                  >
                    <router-link
                      v-if="item == itemp.heading"
                      :to="itemp.route"
                      class="text-link text-category"
                    >
                      {{ itemp.heading }}
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
            </template>
            <li class="breadcrumb-item text-dark opacity-75 text-category">
              {{ title }}
            </li>
          </ul>
        </div>
        <div class="d-flex align-items-center py-3 py-md-1">
          <router-link
            v-if="route.path == '/qaboard'"
            to="/qaboard-create"
            class="btn"
            style="background: #323563;border-radius: 100px;color:#ffffff"
          >
            <i class="las la-plus-square fs-1"></i> สร้างกระทู้
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-else-if="route.path.indexOf('/mandatory-list') > -1"
      class="col-12"
    ></div>
    <div v-else-if="route.path.indexOf('/profile') > -1" class="col-12"></div>
    <div
      v-else-if="
        route.name == 'mandatory-detail' ||
          route.name == 'mandatory-learn' ||
          route.name == 'mandatory-exam'
      "
      class="col-12"
    >
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap mt-5"
      >
        <div class="page-title d-flex flex-column me-3">
          <div class="container">
            <ul
              v-if="breadcrumbs"
              class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            >
              <li class="breadcrumb-item text-dark opacity-75">
                <router-link to="/home" class="text-dark text-category">
                  หน้าหลัก
                </router-link>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item">
                <div class="text-dark opacity-75">
                  <a @click="$router.go(-1)" class="text-link text-category">
                    {{ breadcrumbs[0] }}
                  </a>
                </div>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-dark opacity-75 text-category">
                {{ title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <div
        id="kt_toolbar_container"
        :class="{
          'container-fluid': toolbarWidthFluid,
          container: !toolbarWidthFluid,
        }"
        class="d-flex flex-stack flex-wrap"
      >
        <div class="container page-title d-flex flex-column me-3">
          <!-- <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            {{ title }}
          </h1> -->
          <ul
            v-if="breadcrumbs"
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
          >
            <li class="breadcrumb-item text-dark opacity-75">
              <router-link to="/home" class="text-dark text-category">
                หน้าหลัก
              </router-link>
            </li>
            <li class="breadcrumb-item text-dark opacity-75">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
            <template v-for="(item, index) in breadcrumbs" :key="index">
              <li class="breadcrumb-item">
                <div v-for="(itemlist, i) in MainMenuConfig" :key="i">
                  <div
                    v-for="(itemp, i) in itemlist.pages"
                    :key="i"
                    class="text-dark opacity-75"
                  >
                    <router-link
                      v-if="item == itemp.heading"
                      :to="itemp.route"
                      class="text-link text-category"
                    >
                      {{ itemp.heading }}
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="breadcrumb-item text-dark opacity-75">
                <span class="bullet bg-gray-200 w-5px h-2px"></span>
              </li>
            </template>
            <li class="breadcrumb-item text-dark opacity-75 text-category">
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useRoute } from "vue-router";
import axios from "axios";
const userInfo = ref(null);

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    // Dropdown1,
  },
  data() {
    return {
      i: 0,
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const route = useRoute();
    const showDiv = ref(false);
    const image = ref();
    if (localStorage.getItem("id_token") != null) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getProfile?id=" +
            localStorage.getItem("u_id"),
          {
            headers: {
            token: localStorage.getItem("id_token"),
          },
          }
        )
        .then((response) => {
          userInfo.value = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const showDivForThreeSeconds = () => {
      setTimeout(() => {
        showDiv.value = true;
      }, 2000);
    };
    const getBanner = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getBanner" +
            "?sizeContents=6&page=0&orderBy=1&sort=1&enable=1&displayGroups=1",
          { headers: {
            token: localStorage.getItem("id_token")
              ? localStorage.getItem("id_token")
              : localStorage.getItem("tokenGuest"),
          } }
        )
        .then(({ data }) => {
          image.value = data.data.content;
          // console.log(image.value);
        })
        .catch(({ error }) => {
          console.log(error);
        });
    };
    onMounted(() => {
      showDivForThreeSeconds();
      getBanner();
    });

    return {
      toolbarWidthFluid,
      route,
      MainMenuConfig,
      userInfo,
      showDiv,
      showDivForThreeSeconds,
      getBanner,
      image,
    };
  },
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    //   if (this.$route.path== "/home") {
    //   console.log(this.$route);
    //   setInterval(this.change, 5000);
    // }
    // },
  },
});
</script>
<style scoped>
.btn-login {
  color: #da595a;
}
.text-app {
  color: #da595a;
}
</style>
