<template>
  <div
    class="cookie-consent"
    v-if="!DEDEcookies && Hidecookies == false"
    :key="componentKey"
  >
      <span
        >เว็บไซต์นี้ใช้คุกกี้เพื่อปรับปรุงประสบการณ์ของผู้ใช้
        การใช้เว็บไซต์ของเราแสดงว่าคุณยินยอมให้ใช้คุกกี้ทั้งหมดตามนโยบายคุกกี้ของเรา<router-link
          to="/policy"
          target="_blank"
          class="ml-1 text-decoration-none"
        >
          นโยบายการใช้คุกกี้
        </router-link>
      </span>
      <div class="mt-2 d-flex align-items-center justify-content-center g-2">
        <button class="btn btn-sm btn-success m-1" @click="setCookies">
          ยอมรับ
        </button>
        <button class="btn btn-sm btn-danger  m-1" @click="closeCookies">
          ปิด
        </button>
    </div>
  </div>
</template>
<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 8px;
  left: 20px;
  width: 500px;
  padding-top: 10px;
  height: 150px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background: #292929;
  z-index: 120;
  cursor: pointer;
  border-radius: 3px;
}

.allow-button {
  height: 20px;
  width: 104px;
  color: #fff;
  font-size: 12px;
  line-height: 10px;
  border-radius: 3px;
  border: 1px solid green;
  background-color: green;
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useCookies } from "vue3-cookies";
export default defineComponent({
  name: "Cookies",
  props: {
    // logo: String
  },
  setup() {
    const componentKey = ref(0);
    const DEDEcookies = ref(false);
    const Hidecookies = ref(false);
    const { cookies } = useCookies();

    onMounted(() => {
      if (cookies.get("DEDE-cookies")) {
        DEDEcookies.value = true;
      }
    });
    const setCookies = () => {
      cookies.set("DEDE-cookies", "YES");
      DEDEcookies.value = true;
      componentKey.value += 1;
    };
    const closeCookies = () => {
      Hidecookies.value = true;
      componentKey.value += 1;
    };

    return {
      cookies,
      setCookies,
      DEDEcookies,
      closeCookies,
      Hidecookies,
      componentKey,
    };
  },
});
</script>
