<template>
  <!--begin::Menu wrapper-->
  <div
    class="header-menu align-items-stretch"
    data-kt-drawer="true"
    data-kt-drawer-name="header-menu"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
    data-kt-place="true"
    data-kt-place-mode="prepend"
    data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
  >
    <!--begin::Menu-->
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
      id="#kt_header_menu"
      data-kt-menu="true"
    >
      <template v-for="(item, i) in MainMenuConfig" :key="i">
        <template v-if="!item.heading">
          <template v-for="(menuItem, j) in item.pages" :key="j">
            <div
              v-if="menuItem.heading"
              class="menu-item menu-lg-down-accordion me-lg-1"
            >
              <router-link :to="menuItem.route" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="menu-link py-3"
                  @click="navigate"
                  :class="route.path.indexOf(href) > -1 ? 'active' : ''"
                >
                  <span class="menu-title text-category">{{
                    translate(menuItem.heading)
                  }}</span>
                </a>
              </router-link>
            </div>
          </template>
        </template>
        <div
          v-if="item.heading"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-start"
          class="menu-item menu-lg-down-accordion me-lg-1"
        >
          <span
            class="menu-link py-3"
            :class="{ active: checkActive(item.heading) }"
          >
            <!-- {{item.heading}} -->
            <span class="menu-title text-category">{{
              translate(item.heading)
            }}</span>
            <span class="svg-icon svg-icon-6 mx-2">
              <inline-svg
                v-if="route.path.indexOf('mandatory-list') > -1"
                :src="`media/icon-app/arrow-down1.svg`"
              />
              <inline-svg v-else :src="`media/icon-app/arrow-down.svg`" />
            </span>
          </span>
          <div
            class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-250px"
          >
            <!-- loop menu dropdown -->
            <template v-for="(menuItem, j) in item.pages" :key="j">
              <!-- {{menuItem}} -->
              <div
                v-if="menuItem.sectionTitle"
                data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                class="menu-item menu-lg-down-accordion"
              >
                <span
                  class="menu-link py-3"
                  :class="{ active: hasActiveChildren(menuItem.route) }"
                >
                  <span class="menu-icon">
                    <i
                      v-if="headerMenuIcons === 'font'"
                      :class="menuItem.fontIcon"
                      class="bi fs-3"
                    ></i>
                    <span
                      v-if="headerMenuIcons === 'svg'"
                      class="svg-icon svg-icon-2"
                    >
                      <inline-svg :src="menuItem.svgIcon" />
                    </span>
                  </span>
                  <span class="menu-title text-category">{{
                    translate(menuItem.sectionTitle)
                  }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px"
                >
                  <template v-for="(menuItem1, k) in menuItem.sub" :key="k">
                    <div
                      v-if="menuItem1.sectionTitle"
                      data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                      data-kt-menu-placement="right-start"
                      class="menu-item menu-lg-down-accordion"
                    >
                      <span
                        class="menu-link py-3"
                        :class="{ active: hasActiveChildren(menuItem1.route) }"
                      >
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title text-category">{{
                          translate(menuItem1.sectionTitle)
                        }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div
                        class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px"
                      >
                        <template
                          v-for="(menuItem2, l) in menuItem1.sub"
                          :key="l"
                        >
                          <div class="menu-item">
                            <router-link
                              class="menu-link py-3"
                              active-class="active"
                              :to="menuItem2.route"
                            >
                              <span class="menu-bullet">
                                <span class="bullet bullet-dot"></span>
                              </span>
                              <span class="menu-title text-category">{{
                                translate(menuItem2.heading)
                              }}</span>
                            </router-link>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-if="menuItem1.heading" class="menu-item">
                      <router-link class="menu-link py-3" :to="menuItem1.route">
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title text-category">{{
                          translate(menuItem1.heading)
                        }}</span>
                      </router-link>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="menuItem.heading" class="menu-item">
                <router-link class="menu-link py-3" :to="menuItem.route">
                  <span class="menu-title text-category">{{
                    translate(menuItem.heading)
                  }}</span>
                </router-link>
              </div>
            </template>
            <div v-if="item.heading == 'หลักสูตรตามกฎหมาย'">
              <template v-for="(menuItem, j) in listSyllabuses" :key="j">
                <router-link
                  class="menu-link py-3"
                  :to="'/course/mandatory-list/' + menuItem.id"
                >
                  <span class="menu-title text-category">{{
                    translate(menuItem.name)
                  }}</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>
      </template>
      <!--end::Menu-->
    </div>
  </div>
  <!--end::Menu wrapper-->
</template>

<style lang="scss" scoped>
.header-fixed:not([data-kt-sticky-header="on"])
  .menu-item
  > a
  > .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: #000000;
  scale: 1.1;
  border: 1px solid #dad6d6;
}
.menu-state-bullet-primary .menu-item .menu-link.active {
  background-color: #e9e9e9;
}
.header-fixed:not([data-kt-sticky-header="on"]) .menu-item > a > .menu-link {
  color: #000000;
}

.header-fixed[data-kt-sticky-header="on"] .menu-item > a > .menu-link {
  color: #5e6278;
}

.menu-sub a.menu-link {
  color: #5e6278;
}

.menu-link {
  color: #5e6278;
}

.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item
  > .menu-link
  .menu-title {
  color: #5e6278;
}

.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item
  > .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: #000000;
  scale: 1.1;
  border: 1px solid #dad6d6;
}
.router-link-active.router-link-exact-active.menu-link {
  background-color: rgb(40 35 35 / 10%);
}
.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item.hover
  > .menu-link:not(.disabled):not(.active),
.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item
  > .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: #000000;
}
.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item.hover
  > .menu-link:not(.disabled):not(.active)
  .menu-title,
.header-fixed:not([data-kt-sticky-header="on"])
  .header
  .header-menu
  .menu
  > .menu-item
  > .menu-link:hover:not(.disabled):not(.active)
  .menu-title {
  color: #000000;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import axios from "axios";
import { Token } from "prismjs";
const listSyllabuses = ref();
export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const checkActive = (match) => {
      if (match == "หลักสูตรตามกฎหมาย") {
        return route.path.indexOf("mandatory") !== -1;
      } else if (match == "ศูนย์ช่วยเหลือ") {
        if (
          route.path.indexOf("qaboard") > -1 ||
          route.path.indexOf("help") > -1 ||
          route.path.indexOf("contactus") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    };
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getSyllabus?&sizeContents=10&page=0&orderBy=1&sort=1&enable=1",
          { headers: {
            token: localStorage.getItem("id_token")? localStorage.getItem("id_token") :localStorage.getItem("tokenGuest")
          } }
        )
        .then(({ data }) => {
          // console.log("listSyllabuses");
          // console.log(data.data.content);
          listSyllabuses.value = data.data.content;
        })
        .catch(({ error }) => {
          window.location.reload();
          console.log(error);
        });
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      route,
      listSyllabuses,
      checkActive,
    };
  },
});
</script>
