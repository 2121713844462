<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="topbar d-flex align-items-stretch flex-shrink-0">
      <!--begin::User-->
      <div
        class="d-flex align-items-center me-n3 ms-1 ms-lg-3"
        id="kt_header_user_menu_toggle"
      >
        <!--begin::Menu-->
        <router-link
          to="/sign-in"
          v-if="!userMenu"
          class="btn btn-sm btn-app mx-2"
        >
          เข้าสู่ระบบ
        </router-link>
        <!-- <router-link
          to="/sign-up"
          v-if="!userMenu"
          class="btn btn-sm btn-app mx-2 d-none d-md-block"
        >
          สมัคสมาชิก
        </router-link> -->
        <div
          v-if="userMenu"
          class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
          style="position: relative;"
        >
       
          <img
            :src="userInfo && userInfo.imageProfile ? userInfo.imageProfile : 'media/avatars/blank.png'"
            alt="metronic"
            class="h-30px w-30px h-md-45px w-md-45px"
            style="object-fit:cover;border-radius: 50%;"
            @error="setAltImg"
          />

          <span v-if="userMandatory == 2"
            class="symbol-badge badge badge-circle bg-success"
            style="position: absolute;right: -5px;top: 25px;"
            ><i class="fa fa-check"></i
          ></span>
          <span v-else-if="userMandatory == 1"
            class="symbol-badge badge badge-circle bg-secondary"
            style="position: absolute;right: -5px;top: 25px;"
            ><i class="fa fa-check"></i
          ></span>
        </div>
        <span v-if="userMenu" class="d-none d-lg-block mx-2">
          <b>{{ userInfo.firstName + " " + userInfo.lastName }} </b
          ><br />
          {{ userInfo.email }}
        </span>
        <KTUserMenu></KTUserMenu>
        <!--end::Menu-->
      </div>
      <!--end::User -->
      <!-- {{route.path}} -->
      <!--begin::Heaeder menu toggle-->

      <!-- <div
      
        class="d-flex align-items-center d-lg-none ms-2 me-n3"
        title="Show header menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_header_menu_mobile_toggle"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="media/icons/duotone/Text/Toggle-Right.svg" />
          </span>
        </div>
      </div> -->
      <!--end::Heaeder menu toggle-->
    </div>
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useRoute } from "vue-router";
import axios from "axios";
const userMenu = ref(false);
const userMandatory = ref(false);

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const route = useRoute();
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    if (localStorage.getItem("id_token") != null) {
      axios
        .get(process.env.VUE_APP_API_URL + "/getProfile?id=" + localStorage.getItem("u_id"), {
          headers: {
            "token": localStorage.getItem("id_token")
          },
        })
        .then((response) => {
            //  console.log(response.data.data);
            userMandatory.value = response.data.data.currentSpecialistStatus;
        })
        .catch((error) => {
          console.log(error);
        });
      userMenu.value = true;
      if (userInfo && userInfo.imageProfile) {
        if (userInfo.imageProfile.indexOf("http") ==-1) {
          userInfo.imageProfile = process.env.VUE_APP_IMG_URL + userInfo.imageProfile
        }
      } 
      // console.log(userInfo);
    }
    // onMounted(() => {});
    return {
      route,
      userMenu,
      userInfo,
      userMandatory,
    };
    // watch(
    //   () => route.path,
    //   () => {
    //     nextTick(() => {
    //       reinitializeComponents();
    //     });
    //   }
    // );
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
  },
});
</script>
