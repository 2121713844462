
import { defineComponent, onMounted, ref } from "vue";
import { useCookies } from "vue3-cookies";
export default defineComponent({
  name: "Cookies",
  props: {
    // logo: String
  },
  setup() {
    const componentKey = ref(0);
    const DEDEcookies = ref(false);
    const Hidecookies = ref(false);
    const { cookies } = useCookies();

    onMounted(() => {
      if (cookies.get("DEDE-cookies")) {
        DEDEcookies.value = true;
      }
    });
    const setCookies = () => {
      cookies.set("DEDE-cookies", "YES");
      DEDEcookies.value = true;
      componentKey.value += 1;
    };
    const closeCookies = () => {
      Hidecookies.value = true;
      componentKey.value += 1;
    };

    return {
      cookies,
      setCookies,
      DEDEcookies,
      closeCookies,
      Hidecookies,
      componentKey,
    };
  },
});
