
import { defineComponent, onMounted, ref, watch } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useRoute } from "vue-router";
import axios from "axios";
const userMenu = ref(false);
const userMandatory = ref(false);

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const route = useRoute();
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    if (localStorage.getItem("id_token") != null) {
      axios
        .get(process.env.VUE_APP_API_URL + "/getProfile?id=" + localStorage.getItem("u_id"), {
          headers: {
            "token": localStorage.getItem("id_token")
          },
        })
        .then((response) => {
            //  console.log(response.data.data);
            userMandatory.value = response.data.data.currentSpecialistStatus;
        })
        .catch((error) => {
          console.log(error);
        });
      userMenu.value = true;
      if (userInfo && userInfo.imageProfile) {
        if (userInfo.imageProfile.indexOf("http") ==-1) {
          userInfo.imageProfile = process.env.VUE_APP_IMG_URL + userInfo.imageProfile
        }
      } 
      // console.log(userInfo);
    }
    // onMounted(() => {});
    return {
      route,
      userMenu,
      userInfo,
      userMandatory,
    };
    // watch(
    //   () => route.path,
    //   () => {
    //     nextTick(() => {
    //       reinitializeComponents();
    //     });
    //   }
    // );
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
  },
});
