<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <div class="symbol symbol-50px me-5">
          <img
            alt="Logo"
            :src="userInfo && userInfo.imageProfile ? userInfo.imageProfile : 'media/avatars/blank.png'"
            style="object-fit:cover"
            @error="setAltImg"
          />
        </div>

        <div class="d-flex flex-column" v-if="userInfo">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ userInfo.firstName }} {{ userInfo.lastName }}
            <!-- <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
              >Pro</span
            > -->
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">
            {{ userInfo.email }}
          </a>
        </div>
      </div>
    </div>

    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link to="/profile" class="menu-link px-5">
        ข้อมูลผู้ใช้งาน
      </router-link>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <!-- <div class="menu-item px-5">
      <router-link to="/profile?tab=1" class="menu-link px-5">
        ข้อมูลผู้สมัครภาคบังคับ
      </router-link>
    </div> -->
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <!-- <div class="menu-item px-5">
      <router-link to="/profile?tab=2" class="menu-link px-5">
        หลักสูตรทั่วไปของฉัน
      </router-link>
    </div> -->
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <!-- <div class="menu-item px-5">
      <router-link to="/profile?tab=3" class="menu-link px-5">
        หลักสูตรตามกฎหมายของฉัน
      </router-link>
    </div> -->
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link to="/exam-history" class="menu-link px-5">
        ประวัติการสอบ
      </router-link>
    </div>
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5" style="color:#FF7786">
        ออกจากระบบ
      </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    if (userInfo && userInfo.imageProfile) {
      if (userInfo.imageProfile.indexOf("http") == -1) {
        userInfo.imageProfile =
          process.env.VUE_APP_IMG_URL + userInfo.imageProfile;
      }
    }
    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => router.go(0));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      userInfo,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
  },
});
</script>
