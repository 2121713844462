<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer" style="flex-direction: column;">
    <!--begin::Container-->

    <div class="container text-white">
      <div
        style="display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;"
      >
        <div class="mb-5">
          <div class="my-5">
            <img src="media/image-app/logo-footer.svg" alt="" class="w-100" />
          </div>
          <div class="my-5">
            <img src="media/image-app/logo2.svg" alt="" class="w-100" />
          </div>
        </div>
        <div>
          <b>กลุ่มวิชาการ</b>
          <p class="mb-5" style="font-size: 14px;">
            กองพัฒนาทรัพยากรบุคคลด้านพลังงาน<br />
            กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน <br />
            กระทรวงพลังงาน
          </p>
          <p style="font-size: 14px;">
            ต. คลองห้า อ. คลองหลวง จ. ปทุมธานี 12120 <br />
            โทรศัพท์ 0 2577 7035-41<br />
            โทรสาร 0 2577 7047
          </p>
        </div>
        <div>
          <b>Social Media</b>
          <div style="display: flex;">
            <router-link to="/terms" target="_blank"
            >
              <img class="mx-1" src="media/image-app/Facebook.svg" alt="" />
            </router-link>
            <a
              href="#"
              target="_blank"
            >
              <img class="mx-1" src="media/image-app/Youtube.svg" alt="" />
            </a>
            <a
              href="#"
              target="_blank"
            >
              <img class="mx-1" src="media/image-app/Mail.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{
        'container-fluid': footerWidthFluid,
        container: !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1"
          >&copy; Copy right 2019 . All rights reserved</span
        >
        <!-- <a
          href="https://keenthemes.com/"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >Keenthemes</a
        > -->
      </div>
      <!--end::Copyright-->

      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">เกี่ยวกับเรา</a>
        </li>

        <li class="menu-item">
           <router-link to="/terms" target="_blank" class="menu-link px-2">ข้อกำหนดและเงื่อนไขการใช้งาน</router-link>
        </li>

        <li class="menu-item">
          <router-link to="/policy" target="_blank" class="menu-link px-2">นโยบายความเป็นส่วนตัว</router-link>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
</script>
<style scoped>
.footer {
  background: #323563;
}
hr {
  background-color: #ffffff;
}
</style>
