
import { defineComponent, onMounted, computed, nextTick } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { StickyComponent } from "@/assets/ts/components";

import {
  headerWidthFluid,
  headerFixed,
  headerFixedOnMobile,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  data() {
    return {
      positionTop: document.body.scrollTop,
    };
  },
  watch: {
    $route(to, from) {
      // console.log(this.$route.path);
      if (this.$route.path.indexOf("/course/mandatory-list") == -1) {
        this.$nextTick(() => {
          setInterval(() => {
            this.positionTop = document.body.scrollTop;
          }, 500);
        });
      }
    },
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      StickyComponent.reInitialization();
    });

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });
    const changLogo = () => {
      if (route.path.indexOf("course/mandatory-list") > -1) {
        return "media/icon-app/newlogo2.png";
      } else {
        return "media/icon-app/newlogo1.png";
      }
    };
    const getClass = () => {
      if (route.path == "/home") {
        return "header-default";
      } else if (route.path == "/course/voluntary") {
        return "c-header";
      } else if (route.path == "/course/mandatory") {
        return "c-header";
      } else if (route.path == "/contactus") {
        return "c-header";
      } else if (route.path.indexOf("/blogs") > -1) {
        return "c-header";
      } else if (route.path == "/qaboard") {
        return "c-header";
      } else if (route.path.indexOf("/event") > -1) {
        return "c-header";
      } else if (route.path == "/help") {
        return "c-header";
      } else if (route.path.indexOf("course/mandatory-list") > -1) {
        return "header-mandatory";
      }
    };

    return {
      route,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isHeaderSticky,
      getClass,
      changLogo,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setInterval(() => {
        this.positionTop = document.body.scrollTop;
      }, 100);
    });
  },
  methods: {
    getClassFix() {
      if (this.positionTop > 50 && this.$route.path.indexOf("/home") > -1) {
        return "default-fix";
      }else{
        return "";
      }
    },
    getClassBg() {
      if (
        this.positionTop > 80 &&
        this.$route.path.indexOf("/course/mandatory-list") == -1
      ) {
        return "change_color";
      } else {
        return "";
      }
    },
  },
});
