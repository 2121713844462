const DocMenuConfig: object = [
  {
    pages: [
      {
        heading: "หน้าหลัก",
        route: "/home",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "หลักสูตรทั่วไป",
        route: "/course/voluntary",
        // svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-circle-fill"
      },
    ]
  },
  {
    heading: "หลักสูตรตามกฎหมาย",
    route: "#",
    pages: []
  },
  {
    pages: [
      {
        heading: "ข่าวสารและบทความ",
        route: "/blogs",
        svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "กิจกรรม",
        route: "/event",
        svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers"
      },
    
    ]
  },
  {
    heading: "ศูนย์ช่วยเหลือ",
    route: "#",
    pages: [
      {
        heading: "ถามตอบ",
        route: "/qaboard",
        svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "ช่วยเหลือ",
        route: "/help",
        svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers"
      },
      {
        heading: "ติดต่อเรา",
        route: "/contactus",
        svgIcon: "media/icons/duotone/Interface/Settings-02.svg",
        fontIcon: "bi-layers"
      }
    ]
  },
];

export default DocMenuConfig;
